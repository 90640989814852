<template>
  <article>
    <!-- Início principal -->
      <v-toolbar flat>
        <v-icon>fas fa-code</v-icon>
        <v-toolbar-title class="pl-4">
          Objetos com Movimentação após data de entrega
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>

      <Data :title="$route.name" :dataInicio="camposDatas.dataInicial" :dataFinal="camposDatas.dataFinal" :indicadores="indicadores" />

    <!-- Fim principal -->
  </article>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../../components/Template.vue";
import Data from "./frmData.vue";

export default {
  name: "MovimentoPosEntrega",
  components: {
    Template,
    Data
  },
  data: () => ({
    indicadores: false,
    camposDatas: {
      dataInicial: moment(new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'),
      dataFinal: moment(new Date()).format('YYYY-MM-DD'),
    },

    grid: {
      objetos: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Transp.", value: "transportadora", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Prazo", value: "prazo_entrega", sortable: false },
          { text: "Data Considerada", value: "data_considerada", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Prevista", value: "data_prevista", sortable: true, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Entrega", value: "data_entrega", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Movimentação", value: "data_ultima_posicao", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Movimentação", value: "ultima_posicao", sortable: false },
          { text: "Reclamado", value: "reclamacao", sortable: false },
          { text: "!", value: "alerta", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 50,
        itemSelecionado: [],
        coluna: "objetos.cod_empresa",
        direcao: "asc",
        ordenacao: {
          colunas: [],
          direcao: [
            { text: "ASC", value: "asc" },
            { text: "DESC", value: "desc" },
          ],
        },
      },
      detalhes: {
        cabecalho: [],
        items: [],
        carregando: false,
      },
    },
    modal: {
      filtro: false,
      name: "Movimento Pos Entrega",
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      loading: false,
      cod_transportadora: true,
    },
    filtros: {
      codigo: "",
      cod_transportadora: "",
      numero_rastreio: "",
      prazo: "",
      dt_considerada: "",
      dt_prevista: "",
      dt_entrega: "",
      cep_origem: "",
      cep_destino: "",
      dt_ultima_posicao: "",
      ultima_posicao: "",
    },
    objetoAtual: "",
    numero_nfe: "",
    filtro: false,
    filtroFechar: false,
    rastreios: [],
    detalhes: [],
    loading: false,
    detalhes: {},
    objeto: {
      aba_rastreio: true,
    },
    detmodal: false,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.carregarObjetos();
  },
  computed: {
    formatDateInicio() {
      return this.filtros.data_inicio
        ? moment(this.filtros.data_inicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinal() {
      return this.filtros.data_final
        ? moment(this.filtros.data_final).format("DD/MM/YYYY")
        : "";
    },
    formatDateInicioGrid() {
      return this.dataInicio
        ? moment(this.dataInicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinalGrid() {
      return this.dataFinal ? moment(this.dataFinal).format("DD/MM/YYYY") : "";
    },
    formatDateDataPrevista() {
      return this.filtrosEmpresaCinco.dataPrevista
        ? moment(this.filtrosEmpresaCinco.dataPrevista).format("DD/MM/YYYY")
        : "";
    },
    formatDateDataConsiderada() {
      return this.filtrosEmpresaCinco.dataConsiderada
        ? moment(this.filtrosEmpresaCinco.dataConsiderada).format("DD/MM/YYYY")
        : "";
    },
    formatDateDataEntrega() {
      return this.filtrosEmpresaCinco.dataEntrega
        ? moment(this.filtrosEmpresaCinco.dataEntrega).format("DD/MM/YYYY")
        : "";
    },
    formatDateDataUltPosicao() {
      return this.filtrosEmpresaCinco.dataUltimaPosicao
        ? moment(this.filtrosEmpresaCinco.dataUltimaPosicao).format(
            "DD/MM/YYYY"
          )
        : "";
    },
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.cod_transportadora = "";
      this.filtros.numero_rastreio = "";
      this.filtros.prazo = "";
      this.filtros.dt_considerada = "";
      this.filtros.dt_prevista = "";
      this.filtros.dt_entrega = "";
      this.filtros.cep_origem = "";
      this.filtros.cep_destino = "";
      this.filtros.dt_ultima_posicao = "";
      this.filtros.ultima_posicao = "";
    },
    fecharFiltro() {
      this.modal.filtro = false;
    },
    carregarFiltro( paramFiltro ) {
      this.modal.filtro = false;
      this.filtros = paramFiltro;
      this.carregarObjetos();
    },
    fechar() {
      this.$router.push("/");
    },
    carregarObjetos() {
      this.grid.objetos.items = [];
      this.modal.detalhes = false;
      this.grid.objetos.carregando = true;
      let url = `${baseApiUrl}/objetos`;
      url += `?pos_entrega=S`;
      url += `&page=${this.grid.objetos.paginaAtual}`;
      url += `&coluna=${this.grid.objetos.coluna}&direcao=${this.grid.objetos.direcao}`;
      url += `&fim=${moment(this.camposDatas.dataFinal).format("YYYY-MM-DD")}&inicio=${moment(this.camposDatas.dataInicial).format("YYYY-MM-DD")}`;
      if (this.filtros.numero_rastreio != "")
        url += `&numero_rastreio=${this.filtros.numero_rastreio}`;
      if (this.filtros.cod_transportadora != "")
        url += `&cod_transportadora=${this.filtros.cod_transportadora}`;
      if (Number(this.grid.objetos.porPagina) > 0)
        url += `&por_pagina=${this.grid.objetos.porPagina}`;
      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;

      console.log(url);

      axios
        .get(url)
        .then((res) => {
          this.grid.objetos.items = res.data.dados.data;
          this.grid.objetos.totalRegistros = res.data.dados.total;
          this.grid.objetos.porPagina = Number(res.data.dados.per_page);
        })
        .finally(() => (this.grid.objetos.carregando = false));
    },
    mudarPagina(pagina) {
      this.grid.objetos.paginaAtual = pagina;
      this.carregarObjetos();
    },
    carregarTransportadoras() {
      let url = `${baseApiUrl}/transportadoras`;
      axios.get(url).then((res) => {
        this.transportadoras = res.data.transportadoras;
      });
    },
    async carregarDetalhes(item) {
      this.objeto.item = item;
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },
    fecharDetalhes() {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
        aba_rastreio: true,
      };
    },
    voltarTopo() {
      window.scrollTo(0, 0);
    },
    voltarBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
  },
  watch: {
    "grid.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.objetos.porPagina = 10;
            this.mudarPagina(1);
          }
        }
      },
    },
    "grid.objetos.coluna": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          this.carregarObjetos();
        }
      },
    },
    "grid.objetos.direcao": {
      handler: function (val, oldVal) {
        this.carregarObjetos();
      },
    },
    "objeto.numero_rastreio": {
      handler: function (val, oldVal) {
        if (val == null || val == "") {
          this.objetoAtual = "";
          this.objeto.numero_rastreio = "";
          this.detalhes.enviado.data_postagem = "";
          this.detalhes.enviado.data_considerada = "";
          this.detalhes.enviado.cep = "";
          this.detalhes.enviado.cidade = "";
          this.detalhes.para.nome = "";
          this.detalhes.para.cep = "";
          this.detalhes.para.cidade = "";
          this.detalhes.para.bairro = "";
          this.detalhes.para.rua = "";
          this.detalhes.previsao_de_entrega.data = "";
          this.detalhes.previsao_de_entrega.em_domicilio = "";
          this.detalhes.entregue_em.data = "";
          this.detalhes.ultima_posicao = "";
          this.detalhes.dt_ultima_posicao = "";
          this.detalhes.cod_servico = "";
          this.grid.detalhes.items = [];
          this.rastreio.searchRastreio = "";
          this.rastreio.numero = "";
        }
      },
    },
  },
};
</script>

<style>
</style>